//@flow
import Card from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import ArrowLink from "../components/ArrowLink";
import DontSeeYour from "../components/DontSeeYour";
import ExpandableList from "../components/ExpandableList";
import Footer from "../components/Footer";
import GetStarted from "../components/GetStarted";
import Header from "../components/header";
import Page from "../components/Page";
import Section from "../components/Section";
import { MEDIUM_BREAKPOINT, query } from "../style";
import type {
  ImageSharp,
  ListItemType,
  ImageType,
  Condition,
  Treatment
} from "../types.js";

type Props = {
  data: {
    mainImage: ImageType,
    secondImage: ImageType,
    thirdImage: ImageType,
    allContentfulCondition: { edges: Array<{ node: Condition }> }
  }
};

const Child = ({ condition }: { condition: Condition }) => {
  return (
    <div>
      <ConditionHeader style={{ marginTop: 20 }}>
        What is {condition.name}?
      </ConditionHeader>
      <ConditionBody>{condition.description.description}</ConditionBody>
      {/*<ConditionHeader>
        Why choose the Spine Institute of Idaho to help you?
      </ConditionHeader>
      <ConditionBody>
        {condition.whyChooseSpineIdaho.whyChooseSpineIdaho}
      </ConditionBody>*/}
      <ConditionHeader>What are the most common treatments?</ConditionHeader>
      <ConditionBody>
        {condition.treatmentsDescription.treatmentsDescription}
      </ConditionBody>
      <Treatments>
        {condition.treatments &&
          condition.treatments.map((treat: Treatment) => {
            const letters = treat.name.split(" ");
            return (
              <ListItem key={treat.name}>
                <ListItemText
                  primary={treat.name}
                  secondary={treat.shortDescription}
                />
              </ListItem>
            );
          })}
      </Treatments>
      <ArrowLink style={{ marginLeft: 16 }} to="/services">
        See all procedures we offer
      </ArrowLink>
      <br />
      <br />
    </div>
  );
};

const UnstyledLink = styled(Link)`
  && {
    box-shadow: none;
    text-decoration: none;
    background: none;
    display: block;
  }
`;

const TreatText = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-around;
    text-align: left;
  }
`;

const TreatTitle = styled.span`
  && {
    font-weight: 500;
  }
`;

const TreatDesc = styled.span`
  && {
    font-size: 15px;
    line-height: 15px;
  }
`;

const StyledCard = styled(Card)`
  && {
    padding: 16px;
  }
`;

const StyledCardHeader = styled.div`
  && {
    display: flex;
    align-items: center;
  }
`;

const Treatments = styled(List)``;

const ConditionHeader = styled.h4`
  && {
  }
`;

const ConditionBody = styled.p`
  && {
  }
`;

export default class Index extends React.Component<Props> {
  render() {
    const {
      mainImage,
      secondImage,
      thirdImage,
      allContentfulCondition
    } = this.props.data;

    const conditionsList: Array<ListItemType> = allContentfulCondition.edges.map(
      ({ node }: { node: Condition }) => ({
        name: node.name,
        shortDescription: node.shortDescription,
        children: <Child condition={node} />
      })
    );
    return (
      <Page title="Conditions we treat">
        <Header
          mainImage={mainImage.childImageSharp}
          secondImage={secondImage.childImageSharp}
          thirdImage={thirdImage.childImageSharp}
          title="Conditions we treat"
          subtitle="We have the expertise and technology to make the most accurate diagnosis, and are commited to being there with you every step of the way. Whether you already know what is ailing you, or are looking for answers, trust us to get you on the right path."
          cta="Make an appointment"
        />
        <StyledSection color="#E9E5FA">
          <Intro>
            <Title>We are here to help</Title>
            <Body>
              Whether you already have a diagnosis or are looking for the cause
              of your pain, we are here to listen, provide support and work with
              you throughout your care. Explore some of our commonly treated
              conditions below.
            </Body>
          </Intro>
          <ListSection>
            <ExpandableList list={conditionsList} />
          </ListSection>
        </StyledSection>
        <DontSeeYour
          header="Don't see your condition?"
          body="These conditions are only a small sample of what we treat. If you don’t see what you’re looking for, it doesn’t mean we don’t treat it. Contact us and we’ll be happy to help you."
        />
        <GetStarted />
        <Footer />
      </Page>
    );
  }
}

const StyledSection = styled(Section)`
  && {
    ${query(MEDIUM_BREAKPOINT)`
    padding-top: 250px;
`};
  }
`;

const ListSection = styled.section`
  && {
    margin: 0px -20px;
  }
`;

const Intro = styled.section`
  && {
  }
`;

const Title = styled.h1`
  && {
    text-align: center;
  }
`;

const Body = styled.p`
  && {
    max-width: 500px;
    margin: auto;
  }
`;

export const pageQuery = graphql`
  query conditionsPage {
    mainImage: file(relativePath: { eq: "square/sam-2.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 600) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    secondImage: file(relativePath: { eq: "square/sari.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    thirdImage: file(relativePath: { eq: "square/surgery.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 350) {
          ...GatsbyImageSharpSizes
        }
        resolutions(quality: 70) {
          base64
          srcSet
          src
          originalName
        }
      }
    }
    allContentfulCondition(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          name
          shortDescription
          # treatments {
          # ... on ContentfulProcedure {
          #   name
          #   shortDescription
          # }
          # }
          description {
            description
          }
          link
          # whyChooseSpineIdaho {
          #   whyChooseSpineIdaho
          # }
          treatmentsDescription {
            treatmentsDescription
          }
        }
      }
    }
  }
`;
